@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bg-color: #222222;
  --text-color: #f7cad0;
  --accent-color: #e63946;
}

.dark {
  --bg-color: #f7f7f7;
  --text-color: #333333;
  --accent-color: #e63946;
}

/* Importation des polices */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Reset CSS de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #000;
  color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Ajout de styles responsives */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.space-x-4 {
  > * + * {
    margin-left: 1rem;
  }
}

.space-y-4 {
  > * + * {
    margin-top: 1rem;
  }
}

.bg-black {
  background-color: #000;
}

.text-lightPink {
  color: #ff99cc;
}

.text-darkPink {
  color: #ff3366;
}

.text-secondaryAccent {
  color: #ffaa00;
}

.bg-darkPink {
  background-color: #ff3366;
}

.bg-lightPink {
  background-color: #ff99cc;
}

.hover\:text-darkPink:hover {
  color: #ff3366;
}

.hover\:bg-darkPink:hover {
  background-color: #ff3366;
}

.hover\:bg-lightPink:hover {
  background-color: #ff99cc;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.md\:p-8 {
  @media (min-width: 768px) {
    padding: 2rem;
  }
}
