@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

:root {
  --bg-color: #222222;
  --text-color: #f7cad0;
  --accent-color: #e63946;
}

.dark {
  --bg-color: #f7f7f7;
  --text-color: #333333;
  --accent-color: #e63946;
}

body {
  background-color: #1a1a1a;
  color: #f7f7f7;
  font-family: 'Special Elite', cursive;
}

.bg-darkBackground {
  background-color: #1a1a1a;
}

.text-lightPink {
  color: #f7cad0;
}

.text-darkPink {
  color: #e63946;
}

.text-secondaryAccent {
  color: #ffb703;
}

.p-2 {
  padding: 0.5rem;
}

.bg-gray-800 {
  background-color: #2d2d2d;
}

.bg-gray-900 {
  background-color: #1a1a1a;
}

.border-lightGray {
  border-color: #d3d3d3;
}

.bg-darkPink {
  background-color: #e63946;
}

.hover\\:text-darkPink:hover {
  color: #e63946;
}

.hover\\:text-lightGray:hover {
  color: #d3d3d3;
}

.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  text-decoration: none;
}

